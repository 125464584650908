"use client";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex text-nowrap items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default: "",
        primary:
          "bg-main-accent font-bold  border-2 hover:backdrop-blur  border-transparent hover:border-[#31EB97] hover:bg-[#31EB97]/30 text-main-dark-green hover:text-main-dark-green",

        "primary-light":
          "bg-main-accent !text-main-dark-green hover:!text-white font-bold  border-2 hover:backdrop-blur  border-transparent hover:border-[#31EB97] hover:bg-[#31EB97]/30",

        secondary:
          "backdrop-blur !text-main-dark-green hover:bg-main-accent/30 bg-transparent hover:border-main-accent border-2 border-black  ",

        "secondary-light":
          "backdrop-blur !text-white hover:bg-main-accent/30 bg-transparent hover:border-main-accent border-2 border-white  hover:text-white",
      },

      size: {
        default: "rounded-full px-6 py-2 sm:py-4 sm:px-8 font-semibold",
        sm: "rounded-md px-8 py-4",
        lg: "rounded-full py-4 px-8 sm:px-10 sm:py-6 text-subtitle font-semibold",
        xl: "rounded-full px-10 py-6 sm:px-[72px] sm:py-[34px] text-subtitle font-semibold",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  scrollToColumn?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, scrollToColumn, variant, size, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const handleClick = () => {
      let sectionToScroll = document.getElementById("multi-column");
      if (sectionToScroll) {
        sectionToScroll.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
    if (scrollToColumn) {
      props.onClick = handleClick;
    }
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
