export const apiVersion =
  process.env.NEXT_PUBLIC_SANITY_API_VERSION || "2023-10-15";
export const dataset = assertValue(
  "production",
  "Missing environment variable: NEXT_PUBLIC_SANITY_DATASET"
);

export const projectId = assertValue(
  "wpuzkg3w",
  "Missing environment variable: NEXT_PUBLIC_SANITY_PROJECT_ID"
);
// export const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET;

// export const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;

export const useCdn = true;

function assertValue<T>(v: T | undefined, errorMessage: string): T {
  if (v === undefined) {
    throw new Error(errorMessage);
  }

  return v;
}
