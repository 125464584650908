import { create } from "zustand";

type globalStore = {
  getStartedOpen: boolean;
  setGetStartedOpen: (value: boolean) => void;
};

export const useGlobalStore = create<globalStore>((set) => ({
  getStartedOpen: false,
  setGetStartedOpen: (value) => set({ getStartedOpen: value }),
}));
