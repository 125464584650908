import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const findMinMax = (arr: any) => {
  const min = Math.min(...arr);
  const max = Math.max(...arr);
  return { min, max };
};
export const getCurrentUrlWithoutProtocol = () => {
  if (typeof window !== "undefined") {
    var currentUrl = window.location.href;
    return currentUrl.replace(/^https?:\/\//i, "");
  }
};
export function getCurrentTime() {
  const now = new Date();
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const strMinutes = minutes < 10 ? "0" + minutes : minutes;
  const strHours = hours < 10 ? "0" + hours : hours;

  return strHours + ":" + strMinutes + ampm;
}
